import PropTypes from 'prop-types';
import { SecondaryTabsMenu } from 'bv-components';

const SecondaryNavigation = ({ payload: { linkItems } }) => (
  <SecondaryTabsMenu tabs={linkItems} />
);

SecondaryNavigation.propTypes = {
  payload: PropTypes.shape({
    linkItems: PropTypes.instanceOf(Array).isRequired,
  }).isRequired,
};

export default SecondaryNavigation;
