import { v as bvVar } from 'bv';
import PropTypes from 'prop-types';
import { useRef, useState, useEffect } from 'react';
import { useIntersection } from 'bv-hooks';
import _, { compose, partial } from 'underscore';

import withLazyLoad from 'bv-lazy-load';
import { withErrorBoundary } from 'bv-error-boundary';
import { Skeleton } from 'bv-components';
import { getSkeletonType } from '../helpers';
import SecondaryNavigation from './secondary_navigation';

const withBounderiesLazy = (FallbackComponent) => compose(
  partial(withErrorBoundary, _, { FallbackComponent: () => null }),
  withLazyLoad(FallbackComponent),
);

const withBounderiesLazyAndFallback = (componentKey) => {
  const WithVisibility = (props) => {
    const { position } = props;
    const ref = useRef();
    const [visible, setVisible] = useState(position < 3 || bvVar('prerender'));
    const inViewport = useIntersection(ref, '30%');
    const loader = <Skeleton skeletonType={getSkeletonType(componentKey)} loading />;

    useEffect(() => {
      if (inViewport) setVisible(true);
    }, [inViewport]);

    if (!visible) return <div ref={ref}>{loader}</div>;

    return withBounderiesLazy(loader)(componentKey)({
      ...props,
      isHomeComponent: true,
    });
  };

  WithVisibility.propTypes = {
    position: PropTypes.number.isRequired,
  };

  return WithVisibility;
};

export { SecondaryNavigation as NavigationComponent };
export const BannerComponent = withBounderiesLazyAndFallback('banners');
export const FeaturedComponent = withBounderiesLazyAndFallback('featured');
export const BetBoostComponent = withBounderiesLazyAndFallback('betBoost');
export const UpcomingRacesComponent = withBounderiesLazyAndFallback('upcomingRaces');
export const TrendingBetsComponent = withBounderiesLazyAndFallback('trendingBets');
export const PriceItUpComponent = withBounderiesLazyAndFallback('priceItUp');
export const InPlayWidgetComponent = withBounderiesLazyAndFallback('inPlayWidget');
export const LuckyDipComponent = withBounderiesLazyAndFallback('luckyDip.component');
export const GamesListComponent = withBounderiesLazyAndFallback('gamesList');
export const VideoGamesListComponent = withBounderiesLazyAndFallback('videoGamesList');
export const LiveCasinoGamesListComponent = withBounderiesLazyAndFallback('liveCasinoGamesList');
