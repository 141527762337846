import PropTypes from 'prop-types';
import { FeatureGuardComponent } from 'bv-components';
import * as componentTypes from './components';

const HomeComponentsView = ({ components }) => (
  <div className="home-components-container">
    {components.map(({ type, featureFlipperKey, ...rest }) => {
      const Component = componentTypes[type];

      if (Component) {
        if (!featureFlipperKey) return <Component {...rest} key={rest.componentId} type={type} />;

        return (
          <FeatureGuardComponent feature={featureFlipperKey} key={rest.componentId}>
            <Component {...rest} type={type} />
          </FeatureGuardComponent>
        );
      }

      return null;
    })}
  </div>
);

HomeComponentsView.propTypes = {
  components: PropTypes.instanceOf(Array).isRequired,
};

export default HomeComponentsView;
