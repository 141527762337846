import { underscored } from 'underscore.string';

const SkeletonType = {
  BANNERS: 'site-main-banner-wrapper',
  BET_BOOST: 'bet-boost-market',
  FEATURED: 'featured-component',
  GAMES_LIST: 'home-casino-wrapper',
  IN_PLAY_WIDGET: 'in-play-overview in-play-minicomponent',
  LIVE_CASINO_GAMES_LIST: 'home-live-casino-wrapper',
  LUCKY_DIP: 'lucky-dip-wrapper',
  PRICE_IT_UP: 'priceitup-wrapper',
  TRENDING_BETS: 'trending-bets-wrapper',
  UPCOMING_RACES: 'upcoming-races-wrapper',
  VIDEO_GAMES_LIST: 'home-casino-wrapper',
};

export const getSkeletonType = (componentType) => (
  SkeletonType[underscored(componentType.replace('Component', '')).toUpperCase()]
);
